<template>
  <screen-grid class="leads-general">
    <screen-card
      :title="$t('leads.id.general.section.history')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <ui-history :current-lead="currentLead" :current-lead-history="currentLeadHistory" :is-loading="isLoading" />
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import UiHistory from '@/components/UI/History.vue'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'LeadHistory',
  components: {
    ScreenGrid,
    ScreenCard,
    UiHistory,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentLeadHistory: {
      type: Array,
      required: true,
    },
    currentLead: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    date(date) {
      return formatedDate(date, 'DD/MM/YYYY - HH:mm', this.$i18n.locale)
    },
  },
}
</script>

<style lang="scss" scoped>
.leads-general {
  &__status {
    border-radius: 50px;
    background-color: map-get($generic-color-variants, 'data');
    padding: 4px 8px;
    line-height: 25px;
    white-space: nowrap;
    color: white;
    font-size: 11px;
    font-weight: 300;

    &:before {
      margin-right: 5px;
      content: '⬤';
    }

    &--SATISFIED,
    &--REASSIGN,
    &--TMP_REASSIGN {
      background-color: map-get($generic-color-variants, 'success');
    }

    &--UNSATISFIED,
    &--WONT_REASSIGN,
    &--TMP_WONT_REASSIGN {
      background-color: map-get($generic-color-variants, 'error');
    }

    &--LEAD_CREATION {
      background-color: map-get($generic-color-variants, 'warning');
    }
  }
}
</style>
