<template>
  <modal
    :title="title"
    name="modal-lead-location"
    :has-apply="false"
    :is-updating="isUpdating"
    @save="save"
    @closed="closed"
  >
    <template v-slot:container>
      <ui-input
        class="modal-lead-location__input"
        v-model="modelData.oldLocation"
        :label="$t('modal.lead.label.location.label')"
        :data="modelData.oldLocation"
        readonly
        id="modal-lead-location-old-location"
      >
      </ui-input>
      <ui-dropdown
        class="modal-lead-location__dropdown"
        id="modal-lead-location-location"
        :value="modelLocation"
        :options="locations"
        label="name"
        track-by="name"
        :placeholder="$t('modal.lead.label.location.dropdown.placeholder')"
        :dropdown-label="$t('modal.lead.label.location.dropdown.label')"
        :show-label="true"
        :no-absolute="true"
        :error="$v.modelData.location.$error"
        @input="setLocation"
      >
        <template v-slot:helper v-if="$v.modelData.location.$error && !$v.modelData.location.required">
          {{ $t('errors.required') }}
        </template>
      </ui-dropdown>
    </template>
  </modal>
</template>

<script>
import UiInput from '@/components/UI/Input.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ModalLeadLocation',
  components: {
    UiInput,
    UiDropdown,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: [String, Object, Array, Number],
      required: false,
      default: () => ({
        oldLocation: '',
        location: '',
      }),
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: null,
    }
  },
  async mounted() {
    this.getLocations({ tableParams: null, resetLocations: false, search: false })
    this.modelData = this.data
  },
  computed: {
    ...mapState({
      locations: state => state.location.locations,
    }),
    modelLocation() {
      return this.locations.find(location => location.id === this.modelData.location.id)
    },
  },
  methods: {
    ...mapActions({
      getLocations: 'location/getLocations',
    }),
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.emitSave()
      }
    },
    closed() {
      this.$emit('closed')
    },
    emitSave() {
      this.$emit('save', {
        objKey: { ...this.modelData },
      })
      this.$v.$reset()
    },
    setLocation(payload) {
      this.modelData = {
        ...this.modelData,
        location: payload,
      }
    },
  },
  validations() {
    return {
      modelData: {
        location: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-lead-location {
  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &--successive {
      margin-bottom: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__dropdown {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
