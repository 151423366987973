<template>
  <div class="ui-history">
    <div class="ui-history__block" v-for="day in daysList" :key="day">
      <span class="ui-history__block__date">{{ $tc('leads.id.ui.history.day', day) }}</span>
      <div class="ui-history__block__container">
        <div
          class="ui-history__block__container__item"
          v-for="leadHistory in historyByDate[day]"
          :key="`${leadHistory.id}`"
        >
          <span
            class="ui-history__block__container__item__round"
            :class="`ui-history__block__container__item__round--${leadHistory.action}`"
          />
          <div class="ui-history__block__container__item__info">
            <span class="ui-history__block__container__item__info__title">
              {{ $t(`leads.id.ui.history.title.${leadHistory.action}`) }}
            </span>
            <a
              v-if="getLocationName(leadHistory.locationId)"
              class="ui-history__block__container__item__info__date"
              :href="buildUrlShop(leadHistory.locationId)"
              target="_blank"
            >
              {{ getLocationName(leadHistory.locationId) }}
            </a>
            <span class="ui-history__block__container__item__info__date">
              {{ date(leadHistory.executedAt) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'UiHistory',
  props: {
    currentLeadHistory: {
      type: Array,
      required: true,
      default: () => [],
    },
    currentLead: {
      type: Object,
      required: true,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dateNow: null,
    }
  },
  computed: {
    daysList() {
      let daysList = []

      this.currentLeadHistory.forEach(history => {
        const dayBetween = this.compareDate(history.executedAt)
        if (!daysList.includes(dayBetween)) daysList.push(dayBetween)
      })

      daysList.sort(function (a, b) {
        return a - b
      })

      return daysList
    },
    historyByDate() {
      let historyByDate = []

      this.currentLeadHistory.forEach(history => {
        const dayBetween = this.compareDate(history.executedAt)
        if (!historyByDate[dayBetween]?.length > 0) historyByDate[dayBetween] = []
        historyByDate[dayBetween].push(history)
      })

      return historyByDate
    },
  },
  created() {
    this.dateNow = new Date()
  },
  methods: {
    buildUrlShop(shopId) {
      return `//${this.currentLead.lpeUrl}/${this.currentLead.locale}/${shopId}`
    },
    getLocationName(locationId) {
      const location = this.currentLead.reassignInfo.filter(location => location.locationId === locationId)[0]
      return location ? location.locationName : ''
    },
    compareDate(historyDate) {
      const date = new Date(historyDate)
      return Math.trunc((this.dateNow - date) / (1000 * 3600 * 24))
    },
    date(date) {
      return formatedDate(date, 'DD/MM/YYYY - HH:mm', this.$i18n.locale)
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-history {
  display: flex;
  flex-direction: column;

  &__block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;

    &__date {
      @include title;

      color: map-get($generic-color-variants, 'data');
    }

    &__container {
      display: flex;
      position: relative;
      flex-direction: column;
      gap: 10px;

      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(20px - 1px);
        z-index: 0;
        background-color: $grey-french;
        width: 2px;
        height: calc(100% - 40px);
        content: '';
      }

      &__item {
        display: inline-flex;
        flex-direction: row;
        gap: 10px;

        &__round {
          display: inline-block;
          flex-shrink: 0;
          z-index: 1;
          border-radius: 50%;
          background-color: map-get($generic-color-variants, 'data');
          width: 40px;
          height: 40px;

          &--SATISFIED,
          &--REASSIGN,
          &--TMP_REASSIGN,
          &--DEALER_ACCEPTED,
          &--TMP_DEALER_ACCEPTED,
          &--DEALER_ACCEPTED_VIA_CUSTOMER {
            background-color: map-get($generic-color-variants, 'success');
          }

          &--UNSATISFIED,
          &--WONT_REASSIGN,
          &--TMP_WONT_REASSIGN,
          &--DEALER_REJECTED,
          &--TMP_DEALER_REJECTED,
          &--DEALER_REJECTED_VIA_CUSTOMER {
            background-color: map-get($generic-color-variants, 'error');
          }

          &--LEAD_CREATION {
            background-color: map-get($generic-color-variants, 'warning');
          }
        }
        &__info {
          display: flex;
          flex-direction: column;

          &__title {
            @include sub-title;
          }

          &__date {
            transition: all $transition-duration-default $transition-effect-default;
            color: $grey-french;
            font-weight: 500;
            &:hover {
              color: var(--product-color);
            }
          }
        }
      }
    }
  }
}
</style>
