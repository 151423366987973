<template>
  <div class="leads-id" @scroll.passive="onScroll($event.srcElement, tabsElement)">
    <screen-header
      class="leads-id__header"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading"
      v-if="!hasError"
      :right-content="false"
    >
      <template slot="title">
        <div class="leads-id__header__title">
          <ui-title :title="currentLead.productId + ' - ' + currentLead.locationName" />

          <span
            class="leads-id__header__title__status"
            :class="[`leads-id__header__title__status--${currentLead.locationStatus}`]"
          >
            <template
              v-if="
                currentLead.locationStatus === 'DEALER_ACCEPTED' ||
                currentLead.locationStatus === 'TMP_DEALER_ACCEPTED' ||
                currentLead.locationStatus === 'DEALER_ACCEPTED_VIA_CUSTOMER'
              "
            >
              {{ $t('leads.id.general.label.status.location.accepted') }}
            </template>
            <template v-else-if="currentLead.locationStatus === '' || currentLead.locationStatus === null">
              {{ $t('leads.id.general.label.status.location.none') }}
            </template>
            <template v-else>{{ $t('leads.id.general.label.status.location.rejected') }}</template>
          </span>
        </div>
      </template>

      <div class="leads-id__header__link-to-location__button" v-if="isLoading">
        <skeleton-input margin-left="24px" />
      </div>
      <ui-button
        :label="$t('leads.id.header.goToLocation')"
        :is-link="true"
        button="cta"
        variant="data"
        icon="external_link"
        class="leads-id__header__link-to-location__button"
        v-else
        @click="editLeadLocation"
      />
    </screen-header>
    <screen-error v-if="hasError" :route="{ name: 'Leads' }" :label="$t('leads.id.error.button.back')" />
    <screen-container direction="column" v-else>
      <screen-tabs
        :tabs="tabs"
        :element-fixed="elementFixed"
        :current-tab="currentTab"
        :is-loading="isLoading"
        @mounted="onTabsMounted"
        @click="setCurrentTab"
      />
      <!-- General Section -->
      <lead-general
        v-if="currentTab === 'general'"
        :is-loading="isLoading"
        :current-lead="currentLead"
        @editLeadLocation="editLeadLocation"
      />
      <!-- History Section -->
      <lead-history
        v-if="currentTab === 'history'"
        :is-loading="isLoading"
        :current-lead-history="currentLeadHistory"
        :current-lead="currentLead"
        @editLeadLocation="editLeadLocation"
      />
    </screen-container>
    <!-- Modals -->
    <modal-lead-location
      v-if="isEditing && editingModal === 'location'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :label="modal.label"
      :obj-key="modal.objKey"
      :parameters="modal.parameters"
      @save="saveLeadLocation"
      @closed="closedModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { onScroll } from '@/mixins/scroll.mixin'
import { notif } from '@/mixins/notification.mixin'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenTabs from '@/components/Screen/Tabs.vue'
import ScreenError from '@/components/Screen/Error.vue'
import UiButton from '@/components/UI/Button.vue'
import UiTitle from '@/components/UI/Title.vue'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import ModalLeadLocation from '@/components/Modal/LeadLocation.vue'
import LeadGeneral from '@/components/Lead/General.vue'
import LeadHistory from '@/components/Lead/History.vue'

export default {
  name: 'LeadsId',
  components: {
    ScreenHeader,
    ScreenContainer,
    ScreenTabs,
    ScreenError,
    UiButton,
    UiTitle,
    SkeletonInput,
    ModalLeadLocation,
    LeadGeneral,
    LeadHistory,
  },
  mixins: [onScroll, notif],
  data() {
    return {
      isLoading: false,
      isEditing: false,
      editingModal: '',
      modal: {
        objKey: null,
        data: null,
        label: null,
        parameters: {
          type: 'text',
          mode: 'input',
          required: false,
          number: null,
        },
      },
      hasError: false,
      tabsElement: null,
      customEvents: false,
      customPictures: false,
      customPhotos: false,
      customSeo: false,
    }
  },
  async created() {
    if (!this.$route.params.currentTab) {
      this.setCurrentTab('general')
    }
    this.isLoading = true
    try {
      await this.getLead({ locationId: this.$route.params.locationId, leadId: this.$route.params.id })
    } catch (err) {
      this.$log.error(err)
      this.hasError = true
    } finally {
      this.isLoading = false
    }
    try {
      await this.getLocation({ locationId: this.$route.params.locationId })
    } catch (err) {
      this.$log.error(err)
      this.hasError = true
    } finally {
      this.isLoading = false
    }
  },
  computed: {
    ...mapState({
      currentLead: state => state.lead.currentLead,
      currentLeadHistory: state => state.lead.currentLeadHistory,
      currentLocation: state => state.location.currentLocation,
    }),
    currentTab() {
      return this.$route.params.currentTab
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('leads.list.breadcrumb'),
          route: {
            name: 'Leads',
            params: {
              currentTab: 'list',
            },
          },
        },
        {
          label: this.currentLead.productName,
          route: {
            name: 'Lead',
            params: {
              id: this.currentLead.id,
            },
          },
        },
      ]
    },
    tabs() {
      return [
        {
          label: this.$t('leads.id.tab.general'),
          key: 'general',
        },
        {
          label: this.$t('leads.id.tab.history'),
          key: 'history',
        },
      ]
    },
    modalTitle() {
      return this.$t('common.modal.title.lead')
    },
  },
  methods: {
    ...mapActions({
      getLead: 'lead/getLead',
      getLocation: 'location/getLocation',
    }),
    onTabsMounted(element) {
      this.tabsElement = element
    },
    editLeadLocation() {
      const payload = { oldLocation: this.currentLead.locationName, location: this.currentLocation }
      this.modal.label = this.$t(`leads.id.modal.location`)
      this.modal.data = payload
      this.isEditing = true
      this.editingModal = 'location'
      this.$nextTick(() => {
        this.$modal.show('modal-lead-location')
      })
    },
    saveLeadLocation(params) {
      this._createLocationUpdate(async () => {
        await this.updateLocation(params.objKey)
      }, ['modal-lead-location'])
    },
    closedModal() {
      this.isEditing = false
      this.isUpdating = false
      this.editingModal = ''
    },
    setCurrentTab(tab) {
      this.$router.replace({ ...this.$route, params: { ...this.$route.params, currentTab: tab } })
    },
  },
}
</script>

<style lang="scss">
.leads-id {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__header {
    &__title {
      display: flex;
      flex-direction: row;
      gap: 25px;
      align-items: center;

      &__status {
        display: flex;
        align-items: center;
        border-radius: 50px;
        background-color: map-get($generic-color-variants, 'data');
        padding: 5px 14px;
        white-space: nowrap;
        color: $white;
        font-weight: 300;

        &:before {
          margin-right: 5px;
          content: '⬤';
        }

        &--SATISFIED,
        &--REASSIGN,
        &--TMP_REASSIGN,
        &--DEALER_ACCEPTED,
        &--TMP_DEALER_ACCEPTED,
        &--DEALER_ACCEPTED_VIA_CUSTOMER {
          background-color: map-get($generic-color-variants, 'success');
        }

        &--UNSATISFIED,
        &--WONT_REASSIGN,
        &--TMP_WONT_REASSIGN,
        &--DEALER_REJECTED,
        &--TMP_DEALER_REJECTED,
        &--DEALER_REJECTED_VIA_CUSTOMER {
          background-color: map-get($generic-color-variants, 'error');
        }

        &--LEAD_CREATION {
          background-color: map-get($generic-color-variants, 'warning');
        }
      }
    }

    &__link-to-location {
      &__button {
        position: fixed;
        right: $gutter-mobile;
        bottom: calc(#{$navigation-height} + #{$gutter-mobile});
        z-index: 2;

        @media (min-width: $screen-sm) {
          position: relative;
          right: initial;
          bottom: initial;
          margin-left: $gutter-tablet;
        }

        @media (min-width: $screen-xl) {
          margin-left: $gutter-desktop;
        }
      }
    }
  }
}
</style>
